import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'
import objStore from '../store'

const urlConfig = window.urlConfig
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: urlConfig.url,
 
    

    // baseURL: 'http://192.168.1.107:9000/',
    // baseURL: 'http://xilei.natapp1.cc/',
    // baseURL: 'http://10.8.3.71:9300/',
    // 超时
    timeout: 40000
})


// Add a request interceptor
service.interceptors.request.use(function (config) {
    // Do something before req Fuest is sent
    if (window.sessionStorage.getItem('tokenStr')) {
        config.headers['X-Token'] = "Basic " + window.sessionStorage.getItem('token')
        config.headers['Authorization'] = "Bearer " + window.sessionStorage.getItem('tokenStr')
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
service.interceptors.response.use(function (response) {
    if (response.data.success === false) {
        Message.error({
            message: response.data.message,
            customClass: 'message-override zZindex'
        });

        return;
    }
    
    return response.data;
}, function (error) {
    // Do something with response error
    if (error.response.status === 504 || error.response.status === 404) {
        Message.error('服务器异常')
    } else if (error.response.status === 403) {
        Message.error('权限不足，请联系管理员')
    } else if (error.response.status === 401) {
        if(error.response.data.code == 402){
            Message.error('请跳转至登录页重新登录')
        }else{
            Message.closeAll()
            Message.error('尚未登录，请登录')
        }
        // sessionStorage.removeItem("tokenStr");
        // sessionStorage.removeItem("type");
        // sessionStorage.removeItem("user");
        // sessionStorage.removeItem("show");
        // sessionStorage.removeItem("token");
        // sessionStorage.removeItem("companyId");
        // sessionStorage.removeItem("parkId");
        // let { href } = router.resolve({ path: '/login' });
        // window.location.href = href;

        sessionStorage.removeItem("tokenStr");
        sessionStorage.removeItem("type");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("show");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("companyId");
        sessionStorage.removeItem("parkId");
        sessionStorage.removeItem("menuList");
        objStore.commit("initRoutes", []);
        router.push("/login");
    } else {
        if (error.response.data.message) {
            Message.error(error.response.data.message)
        } else {
            Message.error('未知错误')
        }
    }
    return;
});

export default service
