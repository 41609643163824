import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "./until/api";
import { initMenu } from "./until/menus";
import "./assets/font-awesome/css/font-awesome.css";
import less from "less";
import "./assets/css/flex.css";
import AmapVue from "@amap/amap-vue";
import "@/assets/icon/iconfont.css";
import "view-design/dist/styles/iview.css";
import iView from "view-design";
import "@/until/flexible";
import resetMessage from "./until/msg";
import VueCesium from "vue-cesium";
import "vue-cesium/lib/vc-navigation.css";
import { _ExchangeToken, _Msg } from "../src/api/login/index";

Vue.use(VueCesium, {
  cesiumPath: "https://unpkg.com/cesium@latest/Build/Cesium/Cesium.js",
  accessToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlOTBkZWZlNi1mNmM5LTQ1NWYtOTA5ZC1mNTFkNWViNzIzY2IiLCJpZCI6NDIzOTgsImlhdCI6MTYxMTMwNzM4M30.evPzc45sswPDk2gX_aMt8oRRCEdLPtxN1hS9TTJEWNI"
});
// 导入icon图标
import "@/assets/icon-c/iconfont.css";
// 导入svg组件
import "./svg";
// import VueRouter from 'vue-router';
// import App from 'components/app.vue';
// import Routers from './router.js';

// import 'view-design/dist/styles/iview.css';

Vue.use(iView);
Vue.use(elementUI).use(less);
Vue.prototype.$message = resetMessage;
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// import { Icon } from 'view-design';
// Vue.component('Icon', Icon);

AmapVue.config.version = "2.0"; // 默认2.0，这里可以不修改
AmapVue.config.key = "f0a2add77f8f485f791fe52a615f13b1";
AmapVue.config.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation"
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
router.beforeEach((to, form, next) => {
  //  第三方直接跳转园区
  async function thirdPartyLogin() {
    const url2 = window.location.href;
    const temp2 = url2.split("?")[1];
    const pram2 = new URLSearchParams("?" + temp2);
    const source = Number(pram2.get("source"));
    const accessType = Number(pram2.get("accessType"));
    const accessToken = pram2.get("accessToken");
    await _ExchangeToken({ source, accessType, accessToken }).then(res => {
      if (res) {
        const { token, jwtToken, refreshToken } = res.data;
        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("tokenStr", jwtToken);
        window.sessionStorage.setItem("refreshToken", refreshToken);
        window.sessionStorage.setItem("show", "0");
      } else {
        router.replace("/login");
      }
    });
    await _Msg().then(res => {
      if (res.success === true) {
        window.sessionStorage.setItem(
          "user",
          JSON.stringify(res.data.map.user_name)
        );
        window.sessionStorage.setItem(
          "userId",
          JSON.stringify(res.data.map.id)
        );
        window.sessionStorage.setItem("type", res.data.map.utype);
        window.sessionStorage.setItem("companyId", res.data.map.companyId);
        window.sessionStorage.setItem(
          "menuList",
          JSON.stringify(res.data.menuList)
        );
        window.sessionStorage.setItem("parkId", res.data.map.companyId);
        window.sessionStorage.setItem("companyName", res.data.map.companyName);
        // if (res.data.map.utype === 3) {
        //     window.sessionStorage.setItem("parkId", res.data.map.companyId);
        //     window.sessionStorage.setItem("companyName", res.data.map.companyName);
        // }
        if (sessionStorage.getItem("type") === 3) {
          window.sessionStorage.setItem("show", "0");
          if (res.data.menuList[0].children.length >= 2) {
            router.replace(res.data.menuList[0].children[1].url);
          } else {
            router.replace(res.data.menuList[0].children[0].url);
          }
        } else {
          router.replace("/parkOverview");
          // this.$router.replace("/monitorMap");
        }
      }
    });
  }

  if (window.sessionStorage.getItem("tokenStr")) {
    if (to.path == "/bigScreen") {
      let { href } = router.resolve({ path: "/Screen" });
      window.open(href, "_blank");
    } else if (to.path === "/videoMonitoring") {
      // let {href} = router.resolve({path: '/videoMonitoring'});
      // window.open(href, '_blank');
      // initMenu(router, store, to, form, next)
      next();
    } else {
      initMenu(router, store, to, form, next);
      next();
    }
  } else if (to.path == "/register") {
    next();
  } else if (
    window.location.href.indexOf("soscallRecord") !== -1 &&
    to.path != "/login"
  ) {
    thirdPartyLogin();
  } else {
    if (to.path == "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
