import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
let type = JSON.parse(sessionStorage.getItem('type'))
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register/register.vue'),
  },
  {
    path: '/Screen',
    name: 'Screen',
    component: () => import(/* webpackChunkName: "about" */ '../views/equipmentMonitoring/ScreenTemplate.vue'),
  },
  {
    path: '/videoMonitoring',
    name: 'videoMonitoring',
    component: () => import(/* webpackChunkName: "about" */ '../views/equipmentMonitoring/videoMonitoring.vue'),
  },
  {
    path: '/monitorMap',
    name: 'monitorMap',
    component: () => import(/* webpackChunkName: "about" */ '../views/equipmentMonitoring/monitorMap.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue'),
    redirect:type == 3?'/parkOverview':'/vehicleregistrationMng',
    children: [
      {
        name: "账号中心",
        component: () => import(/* webpackChunkName: "about" */ '../views/home/accCenter.vue'),
        path: "/accCenter",
      },
    ]
  },



]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
