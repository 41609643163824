import Main from "@/views/home/home.vue";
import { _Msg } from "@/api/login";
import { _GetRoute } from "@/api/parksysMng";
export const initMenu = (router, store, to, form, next) => {
    if (store.state.routes.length > 0) {
        return;
    }
    let type = JSON.parse(sessionStorage.getItem('type'));

    if (type === 3) {
        _GetRoute({ companyId: sessionStorage.getItem("parkId"),}).then(res => {
            let fmtRoutes = formatRoutes(res.data.list)
            router.addRoutes(fmtRoutes)
            store.commit("initRoutes", fmtRoutes);

            // let sAccountMenuList = sessionStorage.getItem("menuList");
            // let objAccountMenuList = JSON.parse(sAccountMenuList);
            // let fmtRoutes = formatRoutes(objAccountMenuList);
            // router.addRoutes(fmtRoutes)
            // store.commit("initRoutes", fmtRoutes);
        })

        /*let sAccountMenuList = sessionStorage.getItem("menuList");

        if (sAccountMenuList){
            let objAccountMenuList = JSON.parse(sAccountMenuList);
            let fmtRoutes = formatRoutes(objAccountMenuList);

            router.addRoutes(fmtRoutes)
            store.commit("initRoutes", fmtRoutes);
        }else{
            _GetRoute({ companyId: sessionStorage.getItem("parkId"),}).then(res => {
                let fmtRoutes = formatRoutes(res.data.list)
                router.addRoutes(fmtRoutes)
                store.commit("initRoutes", fmtRoutes);
            })
        }*/

    } else {
        setTimeout(() => {
            let r = [
                {
                    icon: "iconfont icon-yuanqufuwu small",
                    menuName: "园区选择",
                    url: '',
                    code: "",
                    children: [{
                        menuName: "园区选择",
                        code: "parkSelection/vehicleregistrationMng",
                        url: "/vehicleregistrationMng",
                        classIcon: "",
                        children: [

                        ]
                    },
                    ]
                },
                {
                    icon: "iconfont icon-bus small",
                    menuName: "车辆授权",
                    url: '',
                    code: "",
                    children: [{
                        menuName: "车辆授权",
                        code: "vehicleAuth/vehicleAuth",
                        url: "/vehicleAuth",
                        classIcon: "",
                        children: [

                        ]
                    },
                    ]
                },

                /*{
                    icon: "iconfont icon-xitongguanli small",
                    menuName: "APP管理",
                    url: '',
                    code: "",
                    children: [
                        {
                            menuName: "消息管理",
                            code: "appMng/appmessageMng",
                            url: "/appmessageMng",
                            classIcon: "",
                            children: []
                        },
                        {
                            menuName: "APP意见反馈",
                            code: "appMng/appFeedback",
                            url: "/appFeedback",
                            classIcon: "",
                            children: []
                        },
                        {
                            menuName: "PAD意见反馈",
                            code: "appMng/padFeedback",
                            url: "/padFeedback",
                            classIcon: "",
                            children: []
                        }
                    ]
                },*/

                {
                    icon: "iconfont icon-xitongguanli small",
                    menuName: "系统管理",
                    url: '',
                    code: "",
                    children: [{
                        menuName: "园区管理",
                        code: "systemManagement/parkMng",
                        url: "/parkMng",
                        classIcon: "",
                        children: [

                        ]
                    },
                    {
                        menuName: "账号管理",
                        code: "systemManagement/saccountMng",
                        url: "/saccountMng",
                        classIcon: "",
                        children: [

                        ]
                    },

                    ]
                },
            ]
            let fmtRoutes = formatRoutes(r)
            router.addRoutes(fmtRoutes)

            store.commit("initRoutes", fmtRoutes);
        }, 200)
    }
}

export const formatRoutes = (routes) => {
    let fmtRoutes = []

    routes.forEach(router => {
        let { url, menuName, icon, children, level, code, redirect, isMenu, id } = router
        if (children && children.length > 0) {
            children = formatRoutes(children)
        }
        let fmRouter = {}
        if (router.code == "" || router.code == null) {
            fmRouter = {
                id, hidden: isMenu, path: url, redirect, level, name: menuName, children, icon, component: Main
            }
        } else {
            fmRouter = {
                id, hidden: isMenu, path: url, name: menuName, level, children, icon, component(resolve) {
                    require(['../views/' + router.code + '.vue'], resolve)
                }
            }
        }

        fmtRoutes.push(fmRouter)

    })
    return fmtRoutes
}

