<template>
  <div class="homeWrap">
    <el-container style="height: 100%; background: #f5f8fa">
      <el-aside :width="!isCollapse ? '1.047rem' : '0.3125rem'">
        <el-scrollbar style="width: 100%">
          <img class="aside-img" src="../../assets/images/asideImg.png" v-show="!isCollapse" />
          <img
            src="../../assets/images/centerLogo.jpg"
            v-show="isCollapse"
            class="narrow"
          />
          <el-menu
            router
            unique-opened
            :collapse="isCollapse"
            @open="handleOpen"
            @close="handleClose"
            :default-active="this.$route.path"
          >
            <el-submenu
              :index="index + ''"
              v-for="(item, index) in routes"
              v-if="isShow(item)"
            >
              <template slot="title">
                <i :class="[item.icon]" class="icon"></i>
                <span class="iconRight" v-show="!isCollapse">
                  {{ item.name }}
                </span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="children.path"
                  v-for="(children, indexxj) in item.children"
                >
                  <img
                    v-show="currentDicShow(children)"
                    src="@/assets/images/currentIndication.png"
                    alt=""
                    class="currentImg"
                  />
                  {{ children.name }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-container>
        <el-header class="homeHeader">
          <div class="homeTop">
            <div>
              <div class="versionNumber">
                <img
                  src="../../assets/images/headZd.png"
                  class="headZd"
                  @click="fold"
                />
              </div>
              <img
                src="../../assets/images/retrun.png"
                alt=""
                @click="getretrun"
                v-if="type == 3 && show != 0"
              />
            </div>
            <div class="headDrop">
              <!-- <img src="../../assets/images/headSearch.png" class="headZd" />
              <img src="../../assets/images/headTz.png" class="headZd" /> -->
              <span v-if="type == 3" class="versionNumber2">{{
                companyName
              }}</span>
              <img
                src="../../assets/images/headFd.png"
                class="headZd"
                @click="full"
              />
              <!-- <img src="../../assets/images/headWz.png" class="headZd" /> -->
              <div class="headport">
                <img
                  src="../../assets/images/pep_img.png"
                  width="32px"
                  height="32px"
                />
              </div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link"> {{ user }}<i> </i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="accCenter"
                    >账号中心</el-dropdown-item
                  >
                  <el-dropdown-item command="signOut"  
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <img src="../../assets/images/headSz.png" class="headZd" /> -->
            </div>
          </div>
          <div class="homeBtm">
            <h2 class="mt">{{ this.$router.currentRoute.name }}</h2>

            <el-breadcrumb separator=">" class="mart">
              <el-breadcrumb-item class="colB">
                <i class="iconfont icon-fl-jia"></i
                >{{
                  this.$router.currentRoute.matched[0].name
                }}</el-breadcrumb-item
              >
              <el-breadcrumb-item class="col">{{
                this.$router.currentRoute.name
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import screenfull from "screenfull";
import { _LoginOut } from '@/api/login/index'
export default {
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
      isCollapse: false,
      type: JSON.parse(sessionStorage.getItem("type")),
      show: sessionStorage.getItem("show"),
      companyName: sessionStorage.getItem("companyName"),
      env: process.env
    };
  },
  mounted() {

  },
  methods: {
    getretrun() {
      this.$store.commit("initRoutes", []);
      window.sessionStorage.setItem("type", 2);
      this.$router.replace("/vehicleregistrationMng");
    },
    menuClick(index) {
      this.$router.push(index);
    },
    currentDicShow(item) {
      // alert("a");
      if (item.path == this.$router.currentRoute.path) {
        return true;
      } else {
        return false;
      }
    },
    //全屏
    full() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器不允许全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    fold() {
      this.isCollapse = !this.isCollapse;
    },
    handleCommand(command) {
      if (command == "accCenter") {
        this.$router.push("/accCenter");
      } else {
        let id =sessionStorage.getItem('userId') ;
        id = id.slice(1,id.length-1)
        // console.log('点击退出')
        // console.log(id)
        _LoginOut({id:id}).then((res)=>{ 
              console.log(res)
               sessionStorage.removeItem("tokenStr");
                sessionStorage.removeItem("type");
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("show");
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("companyId");
                sessionStorage.removeItem("parkId");
                sessionStorage.removeItem("menuList");
                this.$store.commit("initRoutes", []);
                this.$router.push("/login");
        })


        
      }
    },
  // loginOut(){    
        
  //   },

    isShow(item) {
      if (item.name == "账号中心") {
        return false;
      } else {
        return true;
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
  watch: {
    $route() {
      this.companyName = sessionStorage.getItem("companyName");
      this.type = JSON.parse(sessionStorage.getItem("type"));
      this.show = sessionStorage.getItem("show");
    },
  },
  computed: {
    routes() {
      return this.$store.state.routes;
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: PingFang;
  src: url("../../../public/PingFang Regular.otf"); /* IE9 */
}
.el-submenu .el-menu-item:hover {
  // background: rgb(84, 92, 100) !important;
  background: rgba(22, 131, 217, 0.06) !important;
}
.el-submenu.el-submenu.is-active .icon {
  color: #1683d9;
  font-weight: 600;
}

.el-submenu.is-active .iconRight {
  color: #1683d9;
  font-weight: 600;

  i {
    color: #1683d9;
    font-weight: 600;
  }

  .el-submenu__icon-arrow {
    // transform: translateY(37%);
  }
}
.aside-img {
  width: 100%;
  height: 120px;
}
.container {
  height: 100%;
  background: #f5f8fa;
}

// .el-menu-item.is-active {
//     background: rgba(22, 131, 217, 0.06) !important;
// }

.homeWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.el-main {
  padding: 0px 20px 0px;
}

.el-main::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.el-submenu.is-active .el-menu-item-group > ul {
  background-color: #f1f8fd;
}

.el-menu {
  border-right: 0px;

  .currentImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  .el-menu-item.is-active {
    background-color: #f1f8fd;
    font-size: 14px;
    color: #333;
    font-weight: 600;
  }

  .iconRight {
    margin-left: 8px;
    font-size: 14px;
  }

  .el-menu-item {
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-header {
  padding: 0px;
}

.el-dropdown {
  color: white;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.el-scrollbar__view > .el-menu {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
}

.el-menu::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.el-aside::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.nav .menu::-webkit-scrollbar-thumb {
  margin-right: 1px;
  background: hsla(0, 0%, 70.6%, 0.4);
  background: white;
  background-clip: padding-box;
  min-height: 30px;
  border-radius: 20px;
}

.el-aside {
  color: #333;
  background-color: #fff;
  transition: all 0.2s;
  overflow-y: hidden;
  // text-align: center;
}
.el-menu-item-group > .el-menu-item-group__title {
  padding: 0px !important;
}
.el-menu-item-group__title {
  padding-top: 0px;
}
.homeHeader {
  height: 170px !important;

  .homeTop {
    background-color: #1683d9;
    display: flex;
    height: 56px !important;
    padding: 0px 20px;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;

    .headport {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .versionNumber {
      display: flex;
      align-items: center;
      color: white;
    }
    .versionNumber2 {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin-right: 15px;
    }
    .headZd {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 20px;
    }

    .headDrop {
      display: flex;
      align-items: center;
    }
  }

  .homeBtm {
    background: #f5f8fa;
    justify-content: center;
    flex-direction: column;
    padding: 0px 22px 22px;
    .breadcrumbBox {
      display: flex;
      align-items: center;
      font-size: 16px;
      .el-breadcrumb {
        > span:first-child {
          color: #999;
        }
        span {
          color: #666;
        }
      }
    }
    img {
      vertical-align: top;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.size {
  font-size: 16px;
}
.mt {
  margin-top: 20px;
}
.mart {
  margin-top: 10px;
}
.col {
  // color: #999999;
  font-size: 16px;
}
// ul >li{
//   background-color: #f1f8fd;
// }
.el-menu-item-group {
  background-color: #f1f8fd;
}
</style>
<style scoped>
.colB /deep/ .el-breadcrumb__inner {
  color: #999999 !important;
  font-size: 16px;
}
.col /deep/.el-breadcrumb__inner {
  color: #666666 !important;
  font-size: 16px;
}
/deep/.el-menu-item-group > .el-menu-item-group__title {
  padding: 0px !important;
}
.narrow {
  margin-left: 12px;
  margin-top: 12px;
}
</style>
