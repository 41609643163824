import service from '../../until/api'
export function _Login(data) {
    return service({
        url: 'df-oauth/auth/userlogin',
        method: 'POST',
        data,
        headers: {
            "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
        },
    })
}

export function _Token(data) {
    return service({
        url: 'df-oauth/auth/getJwtToken',
        method: 'get',
        params: data,
        headers: {
            "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
        },
    })
}
export function _Msg(data) {
    return service({
        url: 'user-nacos/login/getUserInfo',
        method: 'get',
        params: data,
    })
}
//获取验证码
export function _GetCode(data) {
    return service({
        url: 'df-oauth/auth/getCode',
        method: 'get',
        params: data,
        headers: {
            "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
        },
    })
}


// /退出登录 向后台发送东西
export function _LoginOut(data) {
    return service({
      url: '/user-nacos/user/userLogout',
      method: 'GET',
      params: data, 
      
    })
  }

//  第三方系统跳转认证
export function _ExchangeToken(data) {
    return service({
        url:'/df-oauth/thirdParty/auth/exchangeToken',
        method: 'post',
        data
    })
  }

  // 获取用户账号是否激活
export function _GetAccountIfActive(params) {
    return service({
      url: '/df-oauth/auth/ifActive',
      method: 'GET',
      params: params
    })
}