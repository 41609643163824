import service from '../../until/api'

export function _ParkaccountMngRnge(data) {
    return service({
        url: 'user-nacos/userPark/getUserParkRange',
        method: 'GET',
        params: data
    })
}

export function _ParkaccountMngList(data) {
    return service({
        url: 'user-nacos/userPark/findAllPage',
        method: 'GET',
        params: data
    })
}
export function _ParkaccountMngdel(data) {
    return service({
        url: 'user-nacos/userPark/deleteUserPark',
        method: 'GET',
        params: data
    })
}
export function _ParkaccountMngup(data) {
    return service({
        url: 'user-nacos/userPark/updateUserPark',
        method: 'POST',
        data
    })
}
export function _ParkaccountMngAdd(data) {
    return service({
        url: 'user-nacos/userPark/addUserPark',
        method: 'POST',
        data
    })
}
export function _ParkaccountMngpwd(data) {
    return service({
        url: 'user-nacos/userPark/updateUserParkPassword',
        method: 'GET',
        params: data
    })
}
export function _ParkaccountMngstatus(data) {
    return service({
        url: 'user-nacos/userPark/updateUserParkStatus',
        method: 'GET',
        params: data
    })
}

//角色
export function _RoleMngList(data) {
    return service({
        url: 'protocol-nacos/sass/role/findAllPage',
        method: 'GET',
        params: data
    })
}
export function _AddroleMngpname(data) {
    return service({
        url: 'user-nacos/role/checkRoleName',
        method: 'GET',
        params: data
    })
}
export function _EditroleMngpname(data) {
    return service({
        url: 'user-nacos/role/checkUpdateRoleName',
        method: 'GET',
        params: data
    })
}
export function _AddroleMng(data) {
    return service({
        url: 'protocol-nacos/sass/role/addRole',
        method: 'post',
        data
    })
}
export function _EditroleMng(data) {
    return service({
        url: 'protocol-nacos/sass/role/updateRole',
        method: 'post',
        data
    })
}
export function _Editrole(data) {
    return service({
        url: 'user-nacos/role/deleteRole',
        method: 'GET',
        params: data
    })
}
export function _RoleList(data) {
    return service({
        url: 'user-nacos/role/findAll',
        method: 'GET',
        params: data
    })
}
//路由
export function _GetRoute(data) {
    return service({
        url: 'system-nacos/menu/findAllMenuForPark',
        method: 'GET',
        params: data
    })
}
export function _GetRojur(data) {
    return service({
        url: 'system-nacos/menu/updateRoleMenu',
        method: 'POST',
        data
    })
}

