import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routes: [
    ]
  },
  mutations: {
    initRoutes(state,data){
      state.routes=data
    }
  },
  actions: {
    // setRoutes(content){
    //   return new Promise((resolve)=>{ 
    //     setTimeout(()=>{
    //       content.commit('initRoutes')
    //       resolve()
    //     },1000)
    //   })
    // }
    setRoutes:(context,data)=>{
      context.commit('initRoutes',data)
    }
  },
  modules: {
  }
})
